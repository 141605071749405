import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DaySpotAndRangePicker,
  Error,
  LoadingLinear,
  MaterialLotDurationTable,
  MaterialStockFilterBox,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import { Card, CardContent, Switch } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

import { config } from '../../configs';

/**
 * @function MaterialLotTimeDuration
 */

export default function MaterialLotTimeDuration({ title, subtitle }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const information = useSelector((state) => state.information);
  const place = useSelector((state) => state.place);
  const material = useSelector((state) => state.material);
  const customer = useSelector((state) => state.customer);
  const materialSource = useSelector((state) => state.materialSource);
  const [page, setPage] = useState(query.get('page') || 1);
  const [size, setSize] = useState(query.get('size') || 10);
  const [startDate, setStartDate] = useState(
    query.get('start') ? new Date(query.get('start')) : null,
  );
  const [endDate, setEndDate] = useState(
    query.get('end') ? new Date(query.get('end')) : null,
  );
  const [allowTimeQuery, setAllowTimeQuery] = useState(false);
  const [selectedPlace, setSlectedPlace] = useState(
    query.get('place') ? { _id: query.get('place') } : null,
  );

  const [selectedStockStatus, setSelectedStockStatus] = useState(
    query.get('stockStatus') ? { _id: query.get('stockStatus') } : null,
  );
  const [selectedMaterial, setSelectedMaterial] = useState(
    query.get('material') ? { _id: query.get('material') } : null,
  );
  const [selectedWorkflow, setSelectedWorkflow] = useState(
    query.get('worflow') ? { _id: query.get('worflow') } : null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const queryDataFromServer = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        actions.materialStockLotAll({
          page,
          size,
          place: selectedPlace?._id || '',
          workflow: selectedWorkflow || '',
          stockStatus: selectedStockStatus || '',
          material: selectedMaterial?._id || '',
          startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
          endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
        }),
      );
      setIsLoading(false);
    } catch (error) {
      dispatch(actions.materialStockLotError());
    }
  };

  useEffect(() => {
    dispatch(
      actions.placeAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );

    dispatch(
      actions.materialAll({
        page: 1,
        size: config.max,
      }),
    );

    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [
    page,
    size,
    selectedPlace,
    selectedWorkflow,
    selectedStockStatus,
    selectedMaterial,
    startDate,
    endDate,
  ]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (!materialStockLot.isLoading && !materialStockLot.isCompleted) {
    return <Error />;
  }

  return (
    <div>
      <div className="flex justify-between items-center">{renderTitle()}</div>

      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-1/3 flex items-center">
                <Switch
                  value={allowTimeQuery}
                  onChange={(event) => {
                    setAllowTimeQuery(event.target.checked);
                    if (event.target.checked !== true) {
                      setStartDate(null);
                      setEndDate(null);
                      history.push(
                        `?page=${page}&size=${size}&place=${
                          selectedPlace?._id || ''
                        }&material=${selectedMaterial?._id || ''}&stockStatus=${
                          selectedStockStatus || ''
                        }&start=&end=`,
                      );
                    }
                  }}
                />
                <div>เปิดใช้การกรองเวลา</div>
              </div>
              <div className="w-full lg:w-2/3">
                <DaySpotAndRangePicker
                  addSearchQuery={true}
                  anotherQuery={`?page=${page}&size=${size}&place=${
                    selectedPlace?._id || ''
                  }&material=${selectedMaterial?._id || ''}&start=${
                    startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
                  }&end=${
                    endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
                  }&workflow=${selectedWorkflow || ''}`}
                  flex
                  size="small"
                  enable={allowTimeQuery}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                  endDate={endDate}
                  defaultUseRange={query.get('isUseRange') === 'true'}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="my-2">
        <MaterialStockFilterBox
          customer={customer}
          material={material}
          place={place}
          source={materialSource}
          selectedMaterial={selectedMaterial}
          selectedPlace={selectedPlace}
          selectedStockStatus={selectedStockStatus}
          setSelectedMaterial={setSelectedMaterial}
          setSelectedPlace={setSlectedPlace}
          setSelectedStockStatus={setSelectedStockStatus}
          allowAddToSeachQuery={true}
          anotherQuery={`?page=${page}&size=${size}&start=${
            startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''
          }&end=${
            endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''
          }&isUseRange=${query.get('isUseRange') || ''}
          `}
          showSourceFilter={false}
          showSupplierFilter={false}
          selectedWorkflow={selectedWorkflow}
          setSelectedWorkflow={setSelectedWorkflow}
          showWorkflowFilter
        />
      </div>
      {materialStockLot.isLoading || isLoading ? (
        <LoadingLinear />
      ) : (
        <div className="my-2">
          <MaterialLotDurationTable
            imsSetting={information?.setting?.ims}
            materialStockLot={materialStockLot}
            multiMaterial
            page={page}
            setPage={setPage}
            setSize={setSize}
            showStatus
            size={size}
            total={materialStockLot.total}
            enableEdit
          />
        </div>
      )}
    </div>
  );
}

MaterialLotTimeDuration.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialLotTimeDuration.defaultProps = {
  title: '',
  subtitle: '',
};
